// vue
import { computed, type Ref } from 'vue'

// types
import type { Nullable } from '@revolutionprep/types'

type ValidationErrors = Partial<Record<string, string>>

export function useValidationErrors (
  validationErrors?: Ref<Nullable<ValidationErrors>>
) {
  /**
   * computed
   * ==================================================================
   */
  const hasErrors = computed(() => {
    if (!validationErrors?.value) {
      return false
    }
    return Boolean(Object.keys(validationErrors.value).length)
  })

  return { hasErrors }
}
